body {
    padding-top: 85px !important;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h3.enzuzo-modal-title {
    color: #0071c1 !important;

}
button.enzuzo-modal-button-accept {
    border: 1px solid #0071c1 !important;
    color: #0071c1 !important;
  
}
button.enzuzo-modal-button-confirm {
    background-color: #0071c1 !important;
}