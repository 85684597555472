
.middle-top {
    padding-top: 60px;
    padding-bottom: 50px;
}

.middle-top h1 {
    font-size: 38px;
    line-height: 46px;
}

.middle-top h2 {
    color: var(--section-inner-heading);
    font-size: var(--heading-secondary-text);
    line-height: 36px;
    margin-bottom: 26px;
    max-width: 75%;
}

.middle-top p {
    line-height: 25px;
    max-width: 85%;
}

.middle-top .sec-img {
    width: 500px;
    height: 400px;
    border-radius: var(--default-radius);
    text-align: center;
    background-color: var(--bg-img-theme);
    padding: 34px 48px;
    float: right;
}

.middle-top .sec-img img {
    width: 100%;
    height: auto;
}

.middle-bottom {
    padding-top: 50px;
    padding-bottom: 42px;
}

.middle-bottom .tradesection {
    background-color: #fbfbfb;
    border-radius: var(--default-radius);
    padding: 50px;
    position: relative;
}

.middle-bottom .tradesection::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -11px;
    width: 95%;
    transform: translateX(-50%);
    height: 300px;
    border-radius: var(--default-radius);
    z-index: -1;
    background: #DBEDFF;
}

.middle-bottom .tradesection h1 {
    font-size: 38px;
    line-height: 46px;
    color: #000;
}

.middle-bottom .tradesection p {
    margin-bottom: 30px;
}

/* .middle-bottom .tradesection .btn-main {
    background-color: var(--bg-main);
    border-color: var(--bg-primary);
    color: var(--bg-primary);
}

.middle-bottom .tradesection .btn-main:hover {
    background-color: var(--bg-primary);
    border-color: var(--bg-primary);
    color: var(--btn-main-text);
    opacity: 1;
} */

.middle-bottom .tradesection img.trade-service-img {
    max-width: 80%;
    float: right;
}

.navigate_earth .container {
    background-image: url('../../images/backgrounds/BF-plant.svg');
    background-repeat: no-repeat;
    background-position: top 60% left 15px;
    background-size: 85%;
}

.navigate_marine .container {
    background-image: url('../../images/backgrounds/DockedShip.svg');
    background-repeat: no-repeat;
    background-position: top 60% left 15px;
    background-size: 85%;
}

.market_compass .container {
    background-image: url('../../images/backgrounds/marketcompass.svg');
    background-repeat: no-repeat;
    background-position: top 55% left 15px;
    background-size: 65%;
}

.navigate_whatsapp .container {
    background-image: url('../../images/backgrounds/whatsap_navip.svg');
    background-repeat: no-repeat;
    background-position: top 55% left 15px;
    background-size: 360px;
}

.interactive_directory .container {
    background-image: url('../../images/backgrounds/ClawGrabber.svg');
    background-repeat: no-repeat;
    background-position: top 45% left 15px;
    background-size: 65%;
}

@media (max-width: 1200px) {
    .middle-top .sec-img {
        max-width: 400px;
        height: 300px;
    }
}

@media (max-width: 992px) {

    .middle-top h1 {
        font-size: 25px;
        line-height: 32px;
    }

    .middle-top h2 {
        line-height: 30px;
        max-width: 100%;
        margin-bottom: 18px;
    }

    .middle-top .sec-img {
        float: left;
        padding: 25px 35px;
        max-width: 100%;
        height: auto;
    }

    .middle-bottom .tradesection {
        padding: 30px;
    }

    .middle-bottom .tradesection img.trade-service-img {
        text-align: center;
        float: left;
        margin: auto;
    }

    .middle-bottom .tradesection .btn-main {
        margin-bottom: 20px;
    }
}

@media (max-width: 767px) {

    .middle-top {
        padding-top: 15px;
        padding-bottom: 10px;
    }

    .middle-top h1 {
        font-size: 25px;
        line-height: 30px;
    }

    .middle-top h2 {
        text-align: center;
    }

    .middle-top p {
        max-width: 100%;
        text-align: center;
    }

    .middle-bottom .tradesection img.trade-service-img {
        float: none;
        max-width: 75%;
    }

    .middle-bottom .col-md-6 {
        text-align: center;
    }

    .navigate_earth .container, 
    .navigate_marine .container, 
    .market_compass .container, 
    .navigate_whatsapp .container, 
    .interactive_directory .container {
        background-image: none;
    }
}

@media (max-width: 585px) {

    .middle-top p {
        font-size: 12px;
        line-height: 15px;
        max-width: 95%;
        margin-right: auto;
        margin-left: auto;
    }

    .middle-top h1 {
        font-size: 18px;
        line-height: 22px;
    }

    .middle-top h2 {
        --heading-secondary-text: 16px;
        line-height: 19px;
    }
}
