
:root {
      
      /* Backaground-variable */
    --bg-main: #fff;
    --bg-primary: #0071C1;
    --bg-img-theme: #F6F9FF;
    --bg-testimonials: #DBEDFF;
    --bg-secondary: #333;

    /* Text Color-variable */
    --bg-primary-text: #fff;
    --dropdown-icon: #0070C0;
    --text-main: #000;
    --text-paragraph: #595959;
    --section-inner-heading: #0071C1;
    --bg-secondary-text-main: #fff;
    --bg-secondary-text: rgba(255, 255, 255, 0.7);
    --btn-main-text: #F8FAFC;
    
    /* Font Size and Font Weight */

        /* Headings */
    --heading-main-text: 40px; 
    --heading-main-font-weight: 700;

    --heading-primary-text: 38px;

    --heading-secondary-text: 30px;

    --heading-font-weight: 700;

    --heading-footer-text: 16px;

        /* Pragraphs */
    --banner-pra-text-size: 18px;
    --banner-pra-font-weight: 600;
    --sec-pra-text-size: 16px;
    --sec-pra-font-weight: 500;

        /* Top Navbar main */
    --link-navbar-top-text: 14px;
    --link-navbar-top-font-weight: 600;

        /* Links and footer fonts */
    --link-footer-text: 14px;
    --link-footer-font-weight: 600;
    --link-footer-bottom-font-weight: 500;
    
    /* Border Radius Variable */
    --default-radius: 10px;
    --navigate-navbar-toggler-icon-bg: url('../images/navbar-icons/SidebarNav.svg') !important ;
    --navigate-navbar-toggler-open-icon-bg: url('../images/navbar-icons/Navbar-close.svg') !important ;

}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap');


body, html{
	width: 100%;
	min-height: 100%;
	padding: 0;
	margin: 0;
}


body {
	position:relative;
    background: var(--bg-main);
	font-family: 'Inter', sans-serif;
	color: var(--text-dark);
    overflow-x: hidden;
}

h1, h2, h3, h4 {
	font-weight: var(--heading-font-weight);
    color: var(--text-main);
}

h1 {
    font-size: var(--heading-main-text);
    line-height: 48px;
}

h2 {
    font-size: var(--heading-primary-text);
    line-height: 46px;
}

p{
	font-weight: 500;
	font-size: 16px;
    color: var(--text-paragraph);
}

section {
    position: relative;
}

.banner-pra {
    font-size: var(--banner-pra-text-size);
    font-weight: var(--banner-pra-font-weight);
}

.btn-main {
    background-color: var(--bg-primary);
    border-color: var(--bg-primary);
    color: var(--btn-main-text);
    font-size: 14px;
    line-height: 17px;
    padding: 12px 18px;
    border-radius: calc(var(--default-radius) - 5px);
    font-weight: 600;
    border: 1px solid;
    text-decoration: none;
}

.btn-main:hover, 
.btn-main:focus,
.btn-main:active, 
.btn:first-child:active, 
:not(.btn-check)+.btn:active  {
    background-color: var(--bg-main);
    border-color: var(--bg-primary);
    color: var(--bg-primary);
}

.btn-main:focus,
.btn-main:active {
    box-shadow: none;
    outline: none;
}

.btn-default {
    border: 1px solid #ECECEC;
    border-radius: calc(var(--default-radius) - 5px);
    color: var(--text-main);
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    background-color: var(--bg-main);
    padding: 12px 28px;
    border: 1px solid #ECECEC;
}

.btn-default:hover {
    background-color: var(--bg-primary);
    border-color: var(--bg-primary);
    color: var(--btn-main-text) !important;
}

.btn-transparent {
    background-color: var(--bg-main);
    border: none;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: var(--text-main);
    padding: 12px 28px;
    transition: all 0.3s;
    text-decoration: none;
    border-radius: calc(var(--default-radius) - 5px);
}

.btn-transparent i {
    position: relative;
    top: 4px;
    font-size: 20px;
}

.btn-transparent:hover, 
.btn-transparent:active, 
.btn-transparent:focus {
    background-color: var(--bg-primary);
    border: none;
    color: var(--bg-primary-text);
}

.contact-card {
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 10px;
    padding: 30px;
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: auto;
    height: 100%;
}

.contact-card.phone {
    background-image: url('../images/contactus/phone.svg');
}

.contact-card.email {
    background-image: url('../images/contactus/email.svg');
}

.contact-card.media {
    background-image: url('../images/contactus/media.svg');
}

.contact-card.partnership {
    background-image: url('../images/contactus/partnership.svg');
}

.contact-card h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
}

.contact-card p {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px !important;
    min-height: 90px;
    margin-bottom: 17px !important;
}

.contact-card .contact-link a {
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: var(--section-inner-heading);
    text-decoration: underline;
}

.contact-card .contact-link a:hover {
    text-decoration: none;
}

.Address_details {
    padding-bottom: 70px;
}

.Address_details .container {
    background: #FBFBFB;
    border-radius: 10px;
    padding: 60px 65px 30px 65px;
    position: relative;
}

.Address_details .container::after {
    content: '';
    position: absolute;
    width: 97%;
    height: 100%;
    background-color: #DBEDFF;
    border-radius: 10px;
    top: 16px;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
}

.Address_details .container h2 {
    text-align: center;
    margin-bottom: 34px;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
}

.Address_details .container p {
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: var(--text-paragraph);
    margin-bottom: 35px;
    max-width: 400px;
}

.Address_details .container .location-add {
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    color: #000;
    width: 100%;
    max-width: 285px;
}

.Address_details .container .location-map .map-container {
    height: 300px;
    width: 100%;
}

.nopage {
    background-image: url('../images/backgrounds/bg-top.svg'), url('../images/backgrounds/bg-bottom.svg');
    background-position: top 10% left, bottom 50% right;
    background-repeat: no-repeat;
}

.nopage .nofound-img {
    border-right: 0px;
}

.nopage .nofound-img img {
    width: 100%;
    max-width: 890px;
    height: auto;
}

.nopage .nofound-img img.server-error {
    max-width: 720px;
}


@media (max-width: 992px) {
    h1 {
        --heading-main-text: 25px;
        line-height: 35px;
    }

    h2 {
        --heading-primary-text: 22px;
        --heading-secondary-text: 18px;
        line-height: 30px;
    }

    .Address_details .container {
        padding: 30px 35px 20px 35px;
    }

    .Address_details .container h2 {
        font-size: 24px;
        line-height: 30px;
    }

    .nopage  {
        background-size: 20%, 20%;
    }
}

@media (max-width: 767px) {

    .Address_details .container p {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 20px;
    }

    .Address_details .container .location-add {
        font-size: 16px;
        line-height: 22px;
    }

    .contact-card p {
        min-height: auto;
    }
}

@media (max-width: 585px) {
    h1 {
        --heading-main-text: 20px;
        line-height: 24px;
    }

    .banner-pra {
       --banner-pra-text-size: 14px;
       --banner-pra-font-weight: 500;
    }
}