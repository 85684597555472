.trusted-client h1 {
    font-size: 22px;
    font-weight: 600;
    line-height: 27px;
    margin-bottom: 30px;
}

.trusted-client .overlay {
    --gradient-width: unset !important
}

.trusted-client .slick-track,
.trusted-client .marquee {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.trusted-client .slick-slide,
.trusted-client .logo-img {
    padding: 10px 15px;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
}

.trusted-client .slick-slide img,
.trusted-client .logo-img img {
    max-width: 100%;
    height: auto;
    max-height: 50px;
    height: 50px;
    margin: auto;
    /* filter: grayscale(100%); */
}

.trusted-client .slick-slide img:hover,
.trusted-client .logo-img img:hover {
    /* filter: grayscale(0); */
}

.trusted-client .slick-slider .slick-arrow {
    display: none !important;
}

.trusted-client .slick-slider:hover .slick-track {
    transform: none;
}

@media (max-width: 992px) {
    .trusted-client .slick-slide,
    .trusted-client .logo-img {
        padding: 5px 5px;
    }
}

@media (max-width: 767px) {
    .trusted-client h1 {
        margin-bottom: 15px;
    }
}

.slick-track:hover {
    /* transform: unset !important; */
    transition: unset !important;
}

.hide-secondry {
    display: none;
}

.main_image {
    display: none;
}