:root {
    --request-demo-heading-color: #000;
    --main-heading-size: 40px;
    --pra-color: #595959;
    --pra-font: 18px;
    --content-bg: #fff;
    --content-border: #ddd;
    --content-radius: 11px;
}

.request-demo {
    padding-top: 90px;
    padding-bottom: 70px;
    /* background-image: url('../../images/backgrounds/bg-top.svg'), url('../../images/backgrounds/bg-bottom.svg');
    background-position: top 15% left, top 27% right;
    background-repeat: no-repeat; */
}

.request-demo h1 {
    font-weight: 800;
    font-size: var(--main-heading-size);
    line-height: 48px;
    color: var(--request-demo-heading-color);
    margin-bottom: 26px;
    text-align: center;
}

.request-demo p {
    font-weight: 600;
    font-size: var(--pra-font);
    line-height: 32px;
    text-align: center;
    color: var(--pra-color);
    margin-bottom: 0;
    max-width: 770px;
    margin-right: auto;
    margin-left: auto;
}

.demo-clock {
    border-radius: 50%;
    background: #fff url('../../images/clock/demo-clock.png') no-repeat center;
    background-size: 100%;
    height: 730px;
    position: relative;
    width: 730px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.demo-clock .clock {
    width: 50%;
    height: 50%;
    border-radius: 50%;
    position: relative;
    background-color: transparent;
    z-index: 15;
    bottom: 4%;
}

.demo-clock .clock .minutes-container,
.demo-clock .clock .hours-container,
.demo-clock .clock .seconds-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.demo-clock .clock .hours {
    position: absolute;
    background: url('../../images/clock/needle-main.svg') no-repeat center;
    height: 62px;
    left: 44%;
    top: -2%;
    transform-origin: 50% 100%;
    width: 50px;
    background-size: 100%;
}

.demo-clock .clock .minutes {
    position: absolute;
    background: url('../../images/clock/hours-needle.svg') no-repeat center;
    left: 47%;
    top: -1%;
    transform-origin: 50% 100%;
    width: 30px;
    height: 36px;
    background-size: 100%;
}

.demo-clock .clock .seconds {
    position: absolute;
    background: url('../../images/clock/hours-needle.svg') no-repeat center;
    background-size: 100%;
    left: 49.5%;
    top: -1%;
    transform-origin: 50% 80%;
    width: 26px;
    height: 32px;
    z-index: 8;
    background-size: 100%;
}

.demo-clock .clock .hours-container {
    animation: rotate 43200s infinite linear;
}

.demo-clock .clock .minutes-container {
    animation: rotate 10800s infinite steps(360);
}

.demo-clock .clock .seconds-container {
    animation: rotate 60s infinite steps(60);
}

.demo-clock .clock .demo-frame {
    width: 275px;
    height: auto;
    position: absolute;
    top: 54%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
}

.demo-clock .clock .demo-frame ul li {
    margin-bottom: 15px;
}

.demo-clock .clock .demo-frame ul li a {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #000;
    position: relative;
    padding-left: 35px;
    padding-right: 35px;
    text-decoration: none;
}

.demo-clock .clock .demo-frame ul li a::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #5C8FE2;
}

.demo-clock .clock .demo-frame ul li a::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid #000;
}

.demo-clock .clock .demo-frame ul li:nth-child(2) a::after {
    right: 9px;
}

@keyframes rotate {
    100% {
        transform: rotateZ(360deg);
    }
}


@media (max-width: 767px) {

    .request-demo {
        padding-top: 20px;
        padding-bottom: 0px;
        background-position: top 20px left, top 60px right;
        background-position: top 20px left, top 160px right;
        background-size: 25%;
    }

    .request-demo h1 {
        --main-heading-size: 24px;
        line-height: 30px;
    }

    .request-demo p {
        --pra-font: 14px;
        line-height: 22px;
        margin-bottom: 40px;
    }

    .demo-clock {
        width: 530px;
        height: 530px;
    }

    .demo-clock .clock .hours {
        width: 20px;
        height: 26px;
    }

    .demo-clock .clock .minutes {
        height: 52px;
        width: 30px;
        top: -5%;
    }

    .demo-clock .clock .demo-frame {
        width: 190px;
    }

    .demo-clock .clock .demo-frame ul li a {
        font-size: 14px;
        padding: 5px 15px;
    }

    .demo-clock .clock .demo-frame ul li a::before {
        width: 10px;
        height: 10px;
    }

    .demo-clock .clock .demo-frame ul li a::after {
        right: 0;
        top: 60%;
    }
}

@media (max-width: 575px) {
    .demo-clock {
        width: 320px;
        height: 320px;
    }

    .demo-clock .clock .hours {
        width: 15px;
        height: 20px;
        top: -3px;
    }

    .demo-clock .clock .minutes {
        height: 30px;
        width: 20px;
        top: -5%;
    }

    .demo-clock .clock .demo-frame {
        width: 70%;
    }

    .demo-clock .clock .demo-frame ul li a {
        font-size: 10px;
        padding: 5px 5px;
    }

    .demo-clock .clock .demo-frame ul li a::before {
        display: none;
    }
    .demo-clock .clock .demo-frame ul li:nth-child(1) a::after {
        right: -4px;
        top:12px;
    }
    .demo-clock .clock .demo-frame ul li:nth-child(2) a::after {
        right: -3.3px;
        top: 12px;
    }
        /* .demo-clock .clock .demo-frame ul li a::after {
        right: -5px;
    } */
}
