

.footer {
    background-color: var(--bg-secondary);
    padding-top: 50px;
    padding-bottom: 20px;
    background-image: url('../../images/backgrounds/bg-footer.svg'), url('../../images/backgrounds/bg-footer-right.svg');
    background-position: top left, bottom right;
    background-repeat: no-repeat;
    background-size: 12%;
}

.footer .top-footer {
    margin-bottom: 130px;
}

.footer h1 {
    font-size: var(--heading-footer-text);
    line-height: 19px;
    color: var(--bg-secondary-text-main);
    margin-bottom: 20px;
    margin-top: 17px;
}

.footer img.logo-img {
    width: 100%;
    max-width: 180px;
    height: auto;
}

.footer .row .col-md-6.col-lg-2 {
   padding-left: 5%;
}

.footer .footer-nav a {
    font-size: var(--link-footer-text);
    font-weight: var(--link-footer-font-weight);
    margin-bottom: 15px;
    color: rgba(255, 255, 255, 0.7);
    text-align: left;
    padding: 5px 5px 5px 0px;
    line-height: 17px;
}

.footer p {
    font-size: var( --link-footer-text);
    font-weight: var(--link-footer-bottom-font-weight);
    color: var(--bg-primary-text);
    line-height: 17px;
}

.footer ul li a {
    font-size: var(--link-footer-text);
    font-weight: var(--link-footer-bottom-font-weight);
    color: #c2c2c2;
    text-decoration: none;
    padding: 2px 8px;
    border-right: 1px solid #c2c2c2;
    line-height: 17px;
}

.footer ul li:last-child a {
    border-right: 0px;
}

@media (max-width: 992px) {

    .footer {
        --link-footer-bottom-font-weight: 400;
    }

    .footer .top-footer {
        margin-bottom: 50px;
    }

    .footer img.logo-img {
        max-width: 180px;
    }

    .footer p {
        margin-bottom: 20px !important;
        text-align: center;
    }

    .footer .nav.justify-content-end {
        justify-content: center !important;
    }

    .footer .nav.justify-content-end li a {
        text-align: center;
    }
}

@media (max-width: 992px) {
    /* .footer .container, .footer .footer-nav a {
        text-align: center;
    } */
}

@media (max-width: 767px) {

    .footer {
        padding-top: 30px;
        background-size: 25%;
    }

    .footer h1 {
        --heading-footer-text: 14px;
    }

    .footer .footer-nav a {
        --link-footer-text: 12px;
    }

    .footer h1 {
        padding-left: 5px;
    }

    .footer .footer-nav a {
        padding: 5px 5px 5px 5px;
    }
}

@media (max-width: 400px) {
    .footer p,
    .footer ul li a {
        font-size: 12px;
    }
}

@media (max-width: 330px) {
    .footer p,
    .footer ul li a {
        font-size: 10px;
    }
}