.top-navbar {
    border-bottom: 1px solid #ECEBEE;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    top: 0 !important;
    left: 0 !important;
    position: fixed !important;
    width: 100% !important;
    z-index: 999;
}

.top-navbar.scroll-top-navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    transition: 0.3s ease-in-out;
}

.top-navbar .navbar-brand img {
    max-width: 165px;
}

.top-navbar .navbar-nav a,
.top-navbar .navbar-nav Link {
    font-size: var(--link-navbar-top-text);
    font-weight: var(--link-navbar-top-font-weight);
    padding-left: 15px !important;
    padding-right: 15px !important;
    color: var( --text-main);
    margin: 0px 6px;
    text-decoration: none;
}

.top-navbar .navbar-nav li:hover a,
.top-navbar .navbar-nav a:active {
    color: var(--bg-primary);
}

.top-navbar .navbar-nav a.btn-main {
    color: var(--bg-primary-text);
    padding: 8px 12px !important;
    border: 1px solid var(--bg-primary);
    line-height: 18px;
}

.top-navbar .navbar-nav a.btn-main:hover,
.top-navbar .navbar-nav a.btn-main:active,
.top-navbar .navbar-nav a.btn-main:focus {
    background-color: var(--bg-main);
    color: var(--bg-primary);
}

.top-navbar .navbar-nav a.btn-default {
    color: var(--text-main);
    padding: 8px 28px !important;
}

.top-navbar .navbar-nav .nav {
    align-items: center;
}

.top-navbar .navbar-nav .nav li.dropdown-mn {
    padding-top: 28px;
    padding-bottom: 28px;
    cursor: pointer;
}

.top-navbar .navbar-nav .nav li.dropdown-mn a img {
    margin-left: 6px;
}

.top-navbar .navbar-nav .nav li.dropdown-mn .dropdown-content {
    display: none;
    position: absolute;
    padding: 30px;
    background-color: #fff;
    width: 100%;
    top: 101%;
    left: 0;
    box-shadow: 0px 24px 48px rgba(0, 0, 0, 0.06);
    z-index: 1;
}

.top-navbar .navbar-nav .nav li.dropdown-mn .dropdown-content::before {
    content: "";
    display: block;
    height: 20px;
    position: absolute;
    top: -20px;
    width: 100%;
    left: 0;
}

.top-navbar .navbar-nav .nav li.dropdown-mn:hover .dropdown-content .container-fluid .row,
.top-navbar .navbar-nav .nav li.dropdown-mn .dropdown-content.open .container-fluid .row,
.top-navbar .navbar-nav .nav li.dropdown-mn.open .dropdown-content .container-fluid .row {
    width: 72%;
    justify-content: flex-end;
}

.top-navbar .navbar-nav .nav li.dropdown-mn .dropdown-content .col {
    max-width: 200px;
    position: relative;
    padding-left: 40px;
}

.top-navbar .navbar-nav .nav li.dropdown-mn .dropdown-content .col .col-icon {
    width: 25px;
    height: 25px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    position: absolute;
    top: 4px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-navbar .navbar-nav .nav li.dropdown-mn .dropdown-content .col h3,
.top-navbar .navbar-nav .nav li.dropdown-mn .dropdown-content .col h3 a {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 10px;
}

.top-navbar .navbar-nav .nav li.dropdown-mn .dropdown-content .col h3 a {
    width: max-content;
}

.top-navbar .navbar-nav .nav li.dropdown-mn .dropdown-content .col a,
.top-navbar .navbar-nav .nav li.dropdown-mn .dropdown-content .col p {
    float: none;
    text-decoration: none;
    display: block;
    text-align: left;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #8D959D;
    width: 130px;
}

.top-navbar .navbar-nav .nav li.dropdown-mn .dropdown-content .col a {
    padding: 2px 5px !important;
    margin: 1px 0px;
}

.top-navbar .navbar-nav .nav li.dropdown-mn .dropdown-content .col p {
    padding: 3px 5px !important;
    margin: 2px 0px;
}

.top-navbar .navbar-nav .nav li.dropdown-mn .dropdown-content .col a:hover {
    background-color: var(--bg-primary);
    color: var(--bg-primary-text);
}

.top-navbar .navbar-toggler {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    padding: 0;
}

.top-navbar .navbar-toggler .navbar-toggler-icon {
    background-image: var(--navigate-navbar-toggler-icon-bg);
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
}

.top-navbar .navbar-toggler.open-active .navbar-toggler-icon {
    background-image: var(--navigate-navbar-toggler-open-icon-bg);
    background-size: 13px;
    background-repeat: no-repeat;
    background-position: center;
}

.top-navbar .navbar-toggler:focus,
.top-navbar .navbar-toggler:active,
.top-navbar .navbar-toggler:hover {
    box-shadow: none;
}

.top-navbar .btn-default-mob {
    text-decoration: none;
    font-size: 12px;
    padding: 6px 19px;
    position: relative;
    right: 40px;
}

@media (max-width: 1200px) and (min-width: 993px) {
    .top-navbar .navbar-brand img {
        max-width: 140px;
    }
    .top-navbar .navbar-nav a {
        padding-right: 10px !important;
        padding-left: 10px !important;
        margin: 0px 3px;
    }
    .top-navbar .navbar-nav a.btn-default {
        padding: 12px 20px !important;
    }
    .top-navbar .navbar-nav a.btn-main {
        padding: 12px 12px !important;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .top-navbar .navbar-nav .nav li.dropdown-mn:hover .dropdown-content,
    .top-navbar .navbar-nav .nav li.dropdown-mn .dropdown-content.open {
        padding: 30px 10px;
    }
    .top-navbar .navbar-nav .nav li.dropdown-mn:hover .dropdown-content .container-fluid .row,
    .top-navbar .navbar-nav .nav li.dropdown-mn .dropdown-content.open .container-fluid .row {
        width: 100%;
        justify-content: flex-start;
    }
}

@media (min-width: 1200px) and (max-width: 1300px) {
    .top-navbar .navbar-nav .nav li.dropdown-mn:hover .dropdown-content .container-fluid .row,
    .top-navbar .navbar-nav .nav li.dropdown-mn .dropdown-content.open .container-fluid .row {
        width: 85%;
    }
}

@media (min-width: 1300px) and (max-width: 1600px) {
    .top-navbar .navbar-nav .nav li.dropdown-mn:hover .dropdown-content .container-fluid .row,
    .top-navbar .navbar-nav .nav li.dropdown-mn .dropdown-content.open .container-fluid .row {
        width: 80%;
    }
}

@media (min-width: 1600px) and (max-width: 1780px) {
    .top-navbar .navbar-nav .nav li.dropdown-mn:hover .dropdown-content .container-fluid .row,
    .top-navbar .navbar-nav .nav li.dropdown-mn .dropdown-content.open .row {
        width: 75%;
    }
}


/* @media (min-width: 1400px) {
    .top-navbar .navbar-nav .nav li.dropdown-mn:hover .dropdown-content .container-fluid .row {
        padding-right: 18%;
    }
  } */

@media (min-width: 992px) {
    .top-navbar .navbar-nav .nav li.dropdown-mn .dropdown-content.open {
        display: none;
    }
    .top-navbar .navbar-nav .nav li.dropdown-mn:hover .dropdown-content {
        display: block;
    }
}

@media (max-width: 992px) {
    .top-navbar {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }
    .top-navbar .navbar-nav .nav li.dropdown-mn .dropdown-content.open {
        display: block;
    }
    .top-navbar .navbar-brand img {
        max-width: 120px;
    }
    .top-navbar .container {
        position: relative;
    }
    .top-navbar .navbar-nav,
    .top-navbar .navbar-nav .nav {
        display: block;
        width: 100%;
    }
    .top-navbar .navbar-nav .nav {
        margin: 20px 0px;
    }
    .top-navbar .navbar-nav .nav li.dropdown-mn {
        position: relative;
        padding-top: 10px;
        padding-top: 10px
    }
    .top-navbar .navbar-nav a {
        padding: 0px 0px !important;
        display: block;
        position: relative;
    }
    .top-navbar .navbar-nav a.btn-default {
        margin-bottom: 15px;
    }
    .top-navbar .navbar-nav .nav li.dropdown-mn .dropdown-content {
        top: 80%;
        padding: 30px 30px 30px 30px;
        box-shadow: none;
        position: relative;
        background: #fff;
    }
    .top-navbar .navbar-nav .nav li.dropdown-mn .dropdown-content .col {
        max-width: 100%;
        margin-bottom: 19px;
        flex: auto;
    }
    .top-navbar .navbar-nav .nav li.dropdown-mn .dropdown-content .col h3,
    .top-navbar .navbar-nav .nav li.dropdown-mn .dropdown-content .col h3 a {
        margin-bottom: 6px;
    }
    .top-navbar .navbar-nav .nav li.dropdown-mn .dropdown-content .col a,
    .top-navbar .navbar-nav .nav li.dropdown-mn .dropdown-content .col p {
        width: 100%;
    }
    .top-navbar .navbar-nav .nav li.dropdown-mn .dropdown-content .col .col-icon {
        left: -5%;
    }
    .top-navbar .navbar-nav .nav li.dropdown-mn a img {
        margin-left: none;
        position: absolute;
        right: 10px;
        top: 45%;
        transform: translateY(-50%);
    }
    .top-navbar .navbar-nav .nav li.login-link {
        display: none;
    }
    .top-navbar .navbar-nav .nav li a.btn-main {
        width: fit-content;
        margin-top: 30px;
    }
    .top-navbar .navbar-nav .nav li.dropdown-mn:focus .dropdown-content {
        display: block;
    }
}

@media (max-width: 767px) {
    .top-navbar .navbar-brand img {
        max-width: 105px;
    }
}