

.terms-sec {
    padding-top: 130px;
    padding-bottom: 100px;
    /* background-image: url('../../images/backgrounds/bg-top.svg'),url('../../images/backgrounds/bg-bottom.svg');
    background-position: top left, top 200px right;
    background-repeat: no-repeat; */
}

.terms-sec h1 {
    font-size: 40px;
    font-weight: 800;
    line-height: 48px;
    margin-bottom: 80px;
}

.terms-content {
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 11px;
    padding: 0px 0px 0px 40px;
    overflow: hidden;
}

.terms-content .accordion .accordion-item .accordion-header {
    padding-right: 40px;
}

.terms-content .accordion .accordion-item .accordion-header .accordion-button {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #050505;
    box-shadow: none;
    background: transparent;
    padding-left: 0;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 40px;
}

.terms-content .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
   color: #0071C1;
}

.terms-content .accordion .accordion-item .accordion-header .accordion-button::after {
    content: url('../../images/terms/non-active-after.svg');
    background-image: none;
    position: absolute;
    top: 45%;
    right: 10px;
    transform: translateY(-50%);
}

.terms-content .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
    content: url('../../images/terms/active-after.svg');
}

.terms-content .accordion .accordion-item .accordion-collapse .accordion-body p {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 25px;
}

.terms-content .accordion .accordion-item .accordion-collapse .accordion-body p.l_span {
    color: #000;
    font-weight: 600;
    margin-bottom: 5px;
}

.terms-content .accordion .accordion-item .accordion-collapse .accordion-body p a {
    color: var(--text-paragraph);
}

.terms-content .accordion .accordion-item .accordion-collapse .accordion-body table tr th,
.terms-content .accordion .accordion-item .accordion-collapse .accordion-body table tr td {
    padding: 30px;
    vertical-align: top;
}

.terms-content .accordion .accordion-item .accordion-collapse .accordion-body table tr th {
    font-size: 20px;
}

.terms-content.cookies_policy {
    padding: 40px;
}

.terms-content.cookies_policy h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 25px;
}

.terms-content.cookies_policy p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 25px;
}

.terms-content.cookies_policy p strong {
    color: #000;
}

@media (max-width: 992px){

    .terms-sec {
        padding-top: 80px;
        padding-bottom: 50px;
    }

    .terms-sec h1 {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 60px;
    }

    .terms-content .accordion .accordion-item .accordion-header .accordion-button {
        font-size: 20px;
    }

    .terms-content.cookies_policy h2 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 20px;
    }
    
    .terms-content.cookies_policy p {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 20px;
    }
}

@media (max-width: 767px){

    .terms-sec {
        padding-top: 30px;
        padding-bottom: 20px;
    }

    .terms-sec h1 {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 40px;
    }

    .terms-content .accordion .accordion-item .accordion-header .accordion-button {
        font-size: 18px;
    }

    .terms-content .accordion .accordion-item .accordion-collapse .accordion-body p {
        font-size: 14px;
        line-height: 22px;
    }

    .terms-content {
        max-width: 95%;
        margin: auto;
    }

    .terms-content .accordion .accordion-item .accordion-header .accordion-button {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .terms-content {
        padding-left: 20px;
    }

    .terms-content .accordion .accordion-item .accordion-header {
        padding-right: 20px;
    }

    .terms-content .accordion .accordion-item .accordion-header .accordion-button::after {
        content: '';
        width: 18px;
        height: 18px;
        background-image: url('../../images/terms/non-active-after.svg');
        background-size: 100%;
        background-position: center;
        top: 50%;
    }
    
    .terms-content .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
        content: '';
        width: 18px;
        height: 18px;
        background-image: url('../../images/terms/active-after.svg');
        background-size: 100%;
        background-position: center;
        top: 50%;
    }

    .terms-content.cookies_policy {
        padding: 20px;
    }
}

@media (max-width: 500px) {
    .terms-content .accordion .accordion-item .accordion-header .accordion-button {
        font-size: 16px;
    }

    .terms-content.cookies_policy h2 {
        font-size: 16px;
    }
}

