
.solution h2 {
    margin-bottom: 20px;
}

.solution p {
    line-height: 32px;
    font-weight: 500;
}

@media (max-width: 585px) {
    .solution h2 {
        --heading-primary-text: 18px; 
        line-height: 22px;
    }

    .solution p {
        --banner-pra-text-size: 12px;
        line-height: 15px;
    }
}