

.middle-banner {
    background-color: var(--bg-primary);
    padding-top: 85px;
    padding-bottom: 85px;
    background-image: url('../../images/backgrounds/Satellite-bg.svg');
    background-position: bottom 15px right;
    background-repeat: no-repeat;
    background-size: 15%;
}

.middle-banner h1 {
    font-size: 36px;
    color: var(--bg-primary-text);
    line-height: 44px;
    margin: auto;
    margin-bottom: 50px;
    max-width: 80%;
}

.middle-banner .btn-main {
    background-color: var(--bg-main);
    border-color: var(--bg-main);
    color: var(--bg-primary);
    padding: 14px 20px;
    font-size: 18px;
    line-height: 22px;
}


@media (max-width: 992px) {

    .middle-banner {
        background-size: 20%;
    }

    .middle-banner h1 {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 30px;
    }

    .middle-banner .btn-main {
        padding: 10px 15px;
        font-size: 14px;
        line-height: 20px;
    }
}

@media (max-width: 767px) {
    .middle-banner {
        background-size: 20%;
        padding-top: 38px;
        padding-bottom: 38px;
    }

    .middle-banner .btn-main {
        padding: 8px 12px;
        font-size: 12px;
        line-height: 18px;
    }

    .middle-banner h1 {
        max-width: 90%;
    }
}

@media (max-width: 585px) {
    .middle-banner h1 {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 20px;
    }
}