

.testimonial {
    background-color: var(--bg-testimonials);
    padding: 60px 0px;
}

.testimonial h1 {
    font-size: var(--heading-primary-text);
    color: var(--text-main);
    margin-bottom: 60px;
}

.testimonial .testimonial-card {
    background-color: var(--bg-main);
    border-radius: var(--default-radius);
    padding: 25px 30px;
    /* width: 580px; */
    margin: auto;
    position: relative;
    background-image: url(../../images/testimonials/qoma-top.svg), url(../../images/testimonials/qoma-bottom.svg);
    background-position: top 27px right 26px, bottom 27px left 26px;
    background-repeat: no-repeat;
    background-size: 27px;
    margin-bottom: 60px;
    /* opacity: .5; */
    -webkit-transform: scale3d(0.8, 0.8, 1);
    transform: scale3d(0.8, 0.8, 1);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}


.testimonial .testimonial-card .user-img {
    width: 53px;
    height: 53px;
    border-radius:var(--default-radius);
    margin-bottom: 15px;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
}

.testimonial .testimonial-card .user-img img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.testimonial .testimonial-card .testimonial-content p {
    text-align: center;
    color: var(--text-paragraph);
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    margin-bottom: 20px;
    min-height: 142px;
    text-align: justify;
}

.testimonial .testimonial-card .user-name h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 0;
}

.testimonial .testimonial-card .user-name .company {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

.testimonial .owl-item.active.center {
    z-index: 1;
}

.testimonial .owl-item.active.center .item.testimonial-card {
    opacity: 1;
    -webkit-transform: scale3d(1.2, 1.1, 1.0);
    transform: scale3d(1.2, 1.0, 1.2);
    height: auto;
    background-color: #FFF;
}

.testimonial .owl-item.active.center .item.testimonial-card .user-img {
    width: 41px;
    height: 53px;
}

.testimonial .owl-item.active.center .item.testimonial-card .testimonial-content, 
.testimonial .owl-item.active.center .testimonial-card .user-name {
    transform: scale3d(0.9, 0.9, 1);
}

/* .testimonial .owl-item.active.center .testimonial-card .user-name h3 {
    font-size: 16px;
    line-height: 25px;
} */

/* .testimonial .owl-item.active.center .testimonial-card .user-name .company {
    font-size: 12px;
    line-height: 22px;
} */

.testimonial .owl-carousel .owl-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    position: relative;
    max-width: fit-content;
    margin: auto;
}

.testimonial .owl-carousel .owl-dots .owl-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #0070C0;
    background-color: transparent;
    margin: 0px 6px;
}

.testimonial .owl-carousel .owl-dots .owl-dot.active {
    background-color: #0070C0;
}

.testimonial .owl-carousel .owl-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -6px;
    width: 160px;
    z-index: 9;
}

.testimonial .owl-carousel .owl-nav button.owl-prev img,
.testimonial .owl-carousel .owl-nav button.owl-next img {
    width: 15px;
}

/* prev next arrows spacing */
.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot {
    margin: 0 -45%;
}

@media (max-width: 1200px) and (min-width: 993px) {
    .testimonial .owl-item.active.center .item.testimonial-card {
        background-color: #FFF;
        position: relative;
        top: 15px;
    }
}

@media (max-width: 992px) {

    .testimonial .testimonial-card,
    .testimonial .owl-item.active.center .item.testimonial-card {
        transform: none;
        margin-bottom: 40px;
    }

    .testimonial .owl-item.active.center .item.testimonial-card .testimonial-content, 
    .testimonial .owl-item .testimonial-card .user-name {
        transform: scale3d(1.0, 1.0, 1);
    }

    .testimonial .owl-item.active.center .item.testimonial-card .user-img {
        width: 53px;
        height: 53px;
    }

    .testimonial h1 {
        --heading-primary-text: 27px;
        line-height: 30px;
    }

    .testimonial .owl-carousel .owl-nav button.owl-prev img,
    .testimonial .owl-carousel .owl-nav button.owl-next img {
        width: 12.5px;
    }
}

@media (max-width: 767px) {

    .testimonial {
        padding: 30px 0px;
    }

    .testimonial .testimonial-card,
    .testimonial .owl-item.active.center .item.testimonial-card {
        margin-bottom: 30px;
    }

    .testimonial .owl-carousel .owl-dots .owl-dot {
        width: 8px;
        height: 8px;
    }

    .testimonial .owl-carousel .owl-nav button.owl-prev img,
    .testimonial .owl-carousel .owl-nav button.owl-next img {
        width: 10px;
    }
}

@media (max-width: 600px) {

    .testimonial h1 {
        margin-bottom: 30px;
    }

    .testimonial .testimonial-card {
        width: 90%;
    }
}

@media (max-width: 582px) {
    .testimonial .testimonial-card .user-name .company {
        font-size: 12px;
    }
    .testimonial h1 {
        --heading-primary-text: 18px;
        line-height: 22px;
    }

    .testimonial .owl-item .item.testimonial-card .testimonial-content p,
    .testimonial .owl-item.active.center .item.testimonial-card .testimonial-content p {
        font-size: 12px;
        line-height: 15px;
    }

    .testimonial .owl-item .testimonial-card .user-name h3 {
        font-size: 14px;
        line-height: 28px;
    }

    .testimonial .testimonial-card {
        width: 98%;
    }
}