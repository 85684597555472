
.data-sources {
    padding-top: 20px;
    padding-bottom: 100px;
}

.data-sources h2 {
    margin-bottom: 60px;
}

.dataSources-list {
    background: #F6F9FF;
    border-radius: 10px;
    padding: 28px 29px;
}

.dataSources-list .datasources-item {
    background-color: #fff;
    padding: 30px 17px;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000;
    width: 100%;
    text-decoration: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.dataSources-list .datasources-item img {
    width: auto;
    height: 100px;
    margin: auto;
    margin-bottom: 30px;
    display: block;
}

.img-sec-content {
    position: absolute;
    top: 0;
    left: 15px;
    height: 100%;
    width: 372px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}

.img-sec-content-right {
    left: auto;
    right: 15px;
}

.img-sec-content h3, 
.integrations .text-sec-side h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #0071C1;
    margin-bottom: 26px;
}

.img-sec-content p, 
.integrations .text-sec-side p {
    font-weight: 500;
    font-size: 16px;
    line-height: 147.02%;
    color: #595959;
}

.dataSources-list.product {
    background-color: #fff;
    border: 1px solid #0071C1;
}

.integrations {
    padding-top: 100px;
    position: relative;
}

.integrations::before {
    content: '';
    position: absolute;
    top: 0;
    left: 20px;
    background-image: url('../../images/methodology/side-line.svg');
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 115px;
    height: 85.1%;
}

.integrations h2 {
    margin-bottom: 60px;
}

.integrations .img-sec-side {
    width: 100%;
    background: #F6F9FF;
    padding: 42px 23px;
    position: relative;
}

.integrations .img-sec-side::before {
    content: '';
    position: absolute;
    top: 30%;
    left: -24%;
    width: 25%;
    height: 65px;
    background-image: url('../../images/methodology/t-line.svg');
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 100%;
}

.integrations .img-sec-side img {
    max-width: 90%;
    margin: auto;
    height: auto;
}
@media (min-width: 1400px) {
    /* .integrations::before {
        background-size: 101.7%;
    } */

    .integrations .img-sec-side:last-child::before {
        top: 22.5%;
    }
}

@media (max-width:1399px) {
    .integrations .img-sec-side::before {
        left: -23.5%;
        top: 30.6%;
    }
}

@media (max-width:1200px) {
    .integrations .img-sec-side::before {
        top: 30%;
    }
}

@media (max-width: 1200px) and (min-width: 992px) {
    .data-sources .container {
        max-width: 1100px !important;
    }

    .dataSources-list .datasources-item {
        padding: 20px 15px;
    }

    .dataSources-list .datasources-item img {
        height: 70px;
    }

    .img-sec-content {
        width: 30%;
        left: 20px;
    }

    .img-sec-content-right {
        left: auto;
        right: 0px;
    }
}

@media (max-width: 992px) {
    .main-banner.center-top-banner {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .data-sources {
        padding-top: 10px;
        padding-bottom: 40px;
    }

    .data-sources h2 {
        margin-bottom: 20px;
    }

    .dataSources-list .datasources-item {
        padding: 20px 20px;
        /* margin: 5px 0px; */
        font-size: 12px;
    }

    .dataSources-list .datasources-item img {
        height: 60px;
    }

    .dataserver-img {
        width: 25%;
    }

    .img-sec-content {
        width: 270px;
    }

    .img-sec-content-right {
        right: 0;
        width: 220px;
    }

    .img-sec-content h3, .integrations .text-sec-side h3 {
        font-size: 22px;
        line-height: 27px;
        margin-bottom: 16px;
    }

    .img-sec-content p, .integrations .text-sec-side p {
        font-size: 14px;
    }

    .integrations .img-sec-side {
        margin-bottom: 20px;
    }

    .integrations::before {
        height: 84.8%;
    }

    .integrations .img-sec-side::before {
        left: -23%;
    }
}

@media (max-width: 992px) and (min-width: 768px) {
    .data-sources .container {
        max-width: 800px !important;
    }
}

@media (max-width: 768px) and (min-width: 768px) {
    .img-sec-content {
        left: 20px;
    }

    .integrations .img-sec-side::before {
        top: 29%;
    }
}

@media (max-width: 767px) {

    .dataSources-list .row .col {
        flex: none;
    }

    .dataSources-list .datasources-item {
        padding: 10px 20px;
        margin: 5px 0px;
        font-size: 16px;
    }

    .dataSources-list .datasources-item img {
        height: 60px;
        margin-bottom: 20px;
    }

    .img-sec-content {
        width: 30%;
    }

    .img-sec-content h3 {
        font-size: 18px;
        line-height: 18px;
    }

    .dataserver-img {
        width: 30%;
    }

    .integrations::before, 
    .integrations .img-sec-side::before {
        display: none;
    }
}

@media (max-width: 575px) {

    .dataserver-img {
        width: 35%;
    }

    .img-sec-content {
        left: 20px;
        width: 30%;
    }

    .img-sec-content-right {
        left: auto;
        right: 10px;
        width: 25%;
    }

    .img-sec-content h3 {
        font-size: 14px;
        margin-bottom: 8px;
        line-height: 18px;
    }

    .img-sec-content p {
        font-size: 12px;
    }
}

@media (max-width: 400px) {
    .dataserver-img {
        width: 45%;
    }

    .img-sec-content {
        left: 10px;
        width: 25%;
    }

    .img-sec-content-right {
        left: auto;
        right: 10px;
        width: 25%;
    }

    .img-sec-content h3 {
        font-size: 12px;
        line-height: 16px;
    }

    .img-sec-content p {
        font-size: 10px;
    }
}