


.data-section {
    padding-top: 45px;
    padding-bottom: 50px;
}

.data-section h2 {
    margin-bottom: 60px;
    text-align: center;
}

.data-section.top-area .sidecard {
    position: relative;
}

.data-section.top-area .sidecard.top-left::after {
    content: '';
    position: absolute;
    background-image: url('../../images/data-coverage/line-left-top.svg');
    background-size: 100%;
    background-position: top 80% center;
    background-repeat: no-repeat;
    top: 0;
    right: -80%;
    width: 80%;
    height: 100%;
}

.data-section.top-area .sidecard.bottom-left::after {
    content: '';
    position: absolute;
    background-image: url('../../images/data-coverage/line-left-bottom.svg');
    background-size: 100%;
    background-position: bottom 65% center;
    background-repeat: no-repeat;
    top: 0;
    right: -80%;
    width: 80%;
    height: 100%;
}

.data-section.top-area .sidecard.top-right::after {
    content: '';
    position: absolute;
    background-image: url('../../images/data-coverage/line-right-top.svg');
    background-size: 100%;
    background-position: top 70% center;
    background-repeat: no-repeat;
    top: 0;
    left: -80%;
    width: 80%;
    height: 100%;
}

.data-section.top-area .sidecard.bottom-right::after {
    content: '';
    position: absolute;
    background-image: url('../../images/data-coverage/line-right-bottom.svg');
    background-size: 100%;
    background-position: bottom 80% center;
    background-repeat: no-repeat;
    top: 0;
    left: -80%;
    width: 80%;
    height: 100%;
}

.data-section.top-area .data-card {
    margin-bottom: 80px;
    position: relative;
    height: auto;
}

.data-section.top-area .sidecard:last-child .data-card {
    margin-bottom: 0;
}

.data-section.top-area .data-card img {
    max-height: 150px;
}

.data-section.top-area .centeral-globe img {
    max-width: 260px;
    height: auto;
    margin: auto;
}

.data-card {
    background: #F6F9FF;
    width: 100%;
    height: 100%;
    padding: 30px;
    text-align: center;
    padding: 30px;
}

.data-card img {
    width: auto;
    max-height: 200px;
    margin-bottom: 20px;
}

.data-card .data-card-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
}

.our-data .data-card {
    background-color: #fff;
}

.our-data .data-card .data-card-title {
    color: #0071C1;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 40px;
}

.our-data .data-card .data-card-title .number-data {
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    color: #000;
}

.data-section.accuracy .nav {
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 10px;
    padding: 15px 12px;
}

.data-section.accuracy .nav .nav-item .nav-link {
    border-radius: 10px;
    width: 100%;
    padding: 18px 50px 18px 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #CBCBCB;
    margin-bottom: 5px;
    position: relative;
}

.data-section.accuracy .nav .nav-item .nav-link.active, 
.data-section.accuracy .nav .nav-item .nav-link:hover {
    background: #F6F9FF;
    color: #0071C1;
    font-weight: 700;
}

.data-section.accuracy .nav .nav-item .nav-link.active::after, 
.data-section.accuracy .nav .nav-item .nav-link:hover::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image: url('../../images/terms/active-after.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.data-section.accuracy .tab-content .tab-pane {
    padding: 20px 10px;
}

.data-section.accuracy .tab-content .tab-pane img {
    width: 100%;
}

@media (max-width: 1199px) and (min-width: 992px) {
    .data-section.top-area .data-card img {
        max-height: 100px;
        height: 100px;
    }

    .data-section.top-area .sidecard.top-left::after, 
    .data-section.top-area .sidecard.top-right::after {
        background-position: top 60% center;
    }

    .data-section.top-area .sidecard.bottom-left::after, 
    .data-section.top-area .sidecard.bottom-right::after {
        background-position: bottom 60% center;
    }
}

@media (max-width: 1399px) and (min-width: 1200px) {
    .data-section.top-area .data-card img {
        max-height: 130px;
    }
}

@media (max-width: 1500px) {
    .our-data .data-card img {
        max-height: 150px;
    }
}

@media (max-width: 1350px) {
    .our-data .data-card img {
        max-height: 110px;
    }
}

@media (max-width: 1200px) {
    .our-data .data-card .data-card-title .number-data {
        font-size: 20px;
    }

    .our-data .data-card .data-card-title {
        font-size: 16px;
    }
}

@media (max-width: 992px) {

    .main-banner.center-top-banner {
        background-size: 15%;
    }

    .data-section.top-area .data-card img {
        max-height: 60px;
        height: 60px;
    }

    .data-section.top-area .centeral-globe img {
        max-width: 170%;
    }

    .data-section.top-area .data-card .data-card-title {
        font-size: 12px;
    }

    .data-card img {
        max-height: 120px;
    }

    .our-data .data-card .data-card-title .number-data {
        font-size: 20px;
    }

    .our-data .data-card .data-card-title {
        font-size: 16px;
    }

    .data-section.top-area .sidecard.top-left::after, 
    .data-section.top-area .sidecard.top-right::after {
        background-position: top 35% center;
    }

    .data-section.top-area .sidecard.bottom-left::after, 
    .data-section.top-area .sidecard.bottom-right::after {
        background-position: bottom 35% center;
    }

    .data-card {
        padding: 15px;
    }

    .data-section.accuracy .nav {
        padding: 10px 8px;
    }

    .data-section.accuracy .nav .nav-item .nav-link {
        padding: 15px 40px 15px 15px;
        font-size: 14px;
    }
}

@media (max-width: 767px) {

    .main-banner.center-top-banner {
        background-size: 15%;
    }

    .data-section {
        padding-top: 20px;
        padding-bottom: 25px;
    }

    .data-section h2 {
        margin-bottom: 30px;
    }
}

.countUpfont{
    font-size: 38px;
    margin-bottom: 10px;
    font-weight: 700;
}